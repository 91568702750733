export default {
    chalWidth:"95%",
    btnWidth:"5%",
    baseDivHeight:"100%",
    mainDivHeight:"100%",
    mainDivWidth:"100%",
    workAreaWidth:"70%",
    workAreaHeight:"100%",
    chalHeight:"10%",
    btnAlignment:"right",
    chalAlignment:"left",
    chatAreaHeight:"60%",
    inputAreaHeight:"30%",
    inputTextAreaHeight:"30%",
    inputTextAreaWidth:"80%",
    inputSendBtnWidth:"20%",
    commandPaletteWidth:"30%",
    commandPaletteHeight:"100%",
    editorAreaHeight:"60%",
    personalViewPortAreaHeight:"20%",
    presentationViewPortAreaHeight:"20%",
}